import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import { styled } from '@mui/system'
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import LongTextCellWithPopover from 'src/dashboard/components/LongTextCellWithPopover'
import {
  getOAuthClientsFn,
  createOAuthClientFn,
  updateOAuthClientFn,
  deleteOAuthClientFn,
} from 'src/api/services/oauth.service'
import LoadingIndicator from 'src/components/LoadingIndicator'
import SectionContainer from 'src/dashboard/components/SectionContainer'
import { queryClient } from 'src/query-client'
import { validateRequiredField } from 'src/share/utils'
import { env } from 'src/env'
import { ENDPOINTS } from 'src/api/constants'
import { CiSearch } from 'react-icons/ci'
import { IoMdClose, IoMdMore } from 'react-icons/io'
import { Images } from 'src/assets'
import { TfiDownload } from 'react-icons/tfi'

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
}

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
)

function OAuthClientPage() {
  const { orgId } = useParams()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [search, setSearch] = useState('')

  const { data, isFetching } = useQuery({
    queryKey: ['oauthClients', orgId],
    queryFn: () => (orgId ? getOAuthClientsFn({ orgId }) : undefined),
  })
  const [updateModalState, setUpdateModalState] = useState<{
    isOpen: boolean
    oauthClient: null | UpdateOAuthClientInput
  }>({
    isOpen: false,
    oauthClient: null,
  })
  const { mutate: createOAuthClient, isLoading: isCreatingOAuthClient } =
    useMutation(createOAuthClientFn, {
      onSuccess: () => {
        queryClient.invalidateQueries('oauthClients')
        handleCloseCreateModal()
      },
    })
  const { mutate: updateOAuthClient, isLoading: isUpdatingOAuthClient } =
    useMutation(updateOAuthClientFn, {
      onSuccess: () => {
        queryClient.invalidateQueries('oauthClients')
        handleCloseUpdateModal()
      },
    })
  const { mutate: deleteOAuthClient, isLoading: isDeletingOAuthClient } =
    useMutation(deleteOAuthClientFn, {
      onSuccess: () => {
        queryClient.invalidateQueries('oauthClients')
      },
    })

  useEffect(() => {
    if (!data) return
  }, [data])

  if (!orgId) return null

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'App Name',
      width: 240,
      headerClassName: 'column-style',
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'client_id',
      headerName: 'Client ID',
      width: 480,
      headerClassName: 'column-style',
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return <LongTextCellWithPopover value={params.value} />
      },
    },
    {
      field: 'client_secret',
      headerName: 'Client Secret',
      width: 460,
      headerClassName: 'column-style',
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return <LongTextCellWithPopover value={params.value} />
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      headerClassName: 'column-style',
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => (
        <ActionsCell row={params.row as UpdateOAuthClientInput} />
      ),
    },
  ]

  const ActionsCell: React.FC<{ row: UpdateOAuthClientInput }> = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <IconButton
          aria-controls="more-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            sx={{ padding: '14px' }}
            onClick={() => {
              handleOpenUpdateModal(row)
            }}
          >
            <img
              style={{ marginRight: '12px' }}
              src={Images.icEdit}
              width="20"
              height="20"
            />
            Edit OAuth Client
          </MenuItem>
          <MenuItem
            sx={{ padding: '14px' }}
            onClick={() => {
              handleDelete(row.client_id)
            }}
          >
            <img
              style={{ marginRight: '12px' }}
              src={Images.icTrash}
              width="20"
              height="20"
            />
            Delete OAuth Client
          </MenuItem>
        </Menu>
      </>
    )
  }

  const handleOpenUpdateModal = (row: any) => {
    setUpdateModalState({
      isOpen: true,
      oauthClient: {
        client_id: row.client_id,
        name: row.name,
        redirect_uris: row.redirect_uris.replace(' ', '\n'),
      },
    })
  }

  const handleCloseUpdateModal = () => {
    setUpdateModalState({ isOpen: false, oauthClient: null })
  }

  const handleDelete = (clientId: string) => {
    if (window.confirm('Are you sure you want to delete this oauth client?')) {
      deleteOAuthClient({ clientId, orgId: parseInt(orgId) })
    }
  }

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true)
  }

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  const handleCreateOAuthClient = (oauthClient: any) =>
    createOAuthClient({ ...oauthClient, orgId })

  const handleUpdateOAuthClient = (oauthClient: any) =>
    updateOAuthClient({ ...oauthClient, orgId })

  if (isFetching) {
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    )
  }

  const filteredRows = data?.data.filter((row: any) =>
    row.name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <>
      <SectionContainer>
        <Box
          sx={{
            height: 600,
            width: 'fit-content',
            '& .column-style': {
              backgroundColor: '#F3F2F7',
              fontWeight: 'bold',
            },
            '& .row-style': {
              color: '#111111',
              fontSize: '14px',
            },
          }}
        >
          <Box className="header-group">
            <div className="product-search">
              <CiSearch size={20} />
              <input
                type="text"
                placeholder="Search OAuth client"
                className="products__search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <OAuthClientsToolbar handleCreateClick={handleOpenCreateModal} />
          </Box>
          <DataGrid
            loading={isFetching || isDeletingOAuthClient}
            columns={columns}
            rows={filteredRows ?? []}
            getRowId={(row) => row.client_id}
            sx={{
              fontSize: '14px',
              color: '#111111',
              mt: 2,
              border: '1px solid #DCDBE8',
              borderRadius: '8px',
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'break-spaces',
                fontWeight: '500',
                fontFamily: 'Inter',
                lineHeight: 1.2,
              },
            }}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Box sx={{ marginBottom: 1 }}>
            <Typography variant="h6" component="h2">
              OAuth2 Connection Details
            </Typography>
          </Box>
          <Typography variant="body1" component="p">
            <strong>Authorize URL:</strong>
            {` ${env.API_URL}/${ENDPOINTS.OAUTH}/authorize`}
          </Typography>
          <Typography variant="body1" component="p">
            <strong>Token URL:</strong>
            {` ${env.API_URL}/${ENDPOINTS.OAUTH}/token`}
          </Typography>
        </Box>
      </SectionContainer>
      <CreateSpModal
        isOpen={isCreateModalOpen}
        handleCreate={handleCreateOAuthClient}
        handleCancel={handleCloseCreateModal}
        isSubmitting={isCreatingOAuthClient}
      />
      <UpdateSpModal
        isOpen={updateModalState.isOpen}
        handleUpdate={handleUpdateOAuthClient}
        handleCancel={handleCloseUpdateModal}
        isSubmitting={isUpdatingOAuthClient}
        spToUpdate={updateModalState.oauthClient}
      />
    </>
  )
}

type OAuthClientsToolbarProps = {
  handleCreateClick: () => void
}

const OAuthClientsToolbar: FC<OAuthClientsToolbarProps> = ({
  handleCreateClick,
}) => {
  return (
    <Box sx={{ marginBottom: 1 }}>
      <Button
        sx={{
          borderRadius: '12px',
          '&:hover': { backgroundColor: '#303030' },
          backgroundColor: '#303030',
        }}
        variant="contained"
        color="primary"
        onClick={handleCreateClick}
      >
        New OAuth Client
      </Button>
    </Box>
  )
}

type CreateOAuthClientInput = {
  name: string
  redirect_uris: string
}

type CreateOAuthClientModalProps = {
  isOpen: boolean
  handleCreate: (servideProvider: any) => void
  handleCancel: () => void
  isSubmitting: boolean
}

const CreateSpModal: FC<CreateOAuthClientModalProps> = ({
  isOpen,
  handleCreate,
  handleCancel,
  isSubmitting,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    reset,
  } = useForm<CreateOAuthClientInput>()

  const { orgId } = useParams()
  const [snack, setSnack] = useState(false)

  const copy = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url)
      setSnack(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    reset()
  }, [isOpen])

  const createOAuthClient = () => {
    if (!isDirty || !isValid) return
    const oauthClient = getValues()
    oauthClient.redirect_uris = oauthClient.redirect_uris.replace('\n', ' ')
    handleCreate(oauthClient)
  }

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: 'white',
          position: 'absolute',
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            New OAuth Client
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <Box className="title-modal">
          <Box sx={{ marginTop: 2 }}>
            <Box sx={{ marginBottom: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography fontSize={18}>OAuth2 Connection Details</Typography>
                <Button
                  sx={{ color: '#111111' }}
                  href={`${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                  download="metadata.xml"
                >
                  <TfiDownload size={16} />
                  <Typography sx={{ marginLeft: '5px' }} fontSize={16}>
                    Metadata
                  </Typography>
                </Button>
              </Box>

              <Typography color="#666666" variant="body1" component="p">
                Add the following information to your SAML Service Provider
              </Typography>
            </Box>
            <Typography color="#222449" variant="body1" component="p">
              <span style={{ color: '#666666' }}>Metadata URL:</span>
              <br></br>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    copy(
                      ` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`
                    )
                  }
                  width={24}
                  height={24}
                  src={Images.icCopy}
                />
              </div>
            </Typography>
            <Typography color="#222449" mt={2} variant="body1" component="p">
              <span style={{ color: '#666666' }}>SSO URL:</span>
              <br></br>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`}
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    copy(` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`)
                  }
                  width={24}
                  height={24}
                  src={Images.icCopy}
                />
              </div>
            </Typography>
          </Box>
        </Box>
        <form
          style={{ padding: '30px 60px', width: '100%' }}
          onSubmit={handleSubmit(createOAuthClient)}
        >
          <TextField
            {...register('name', {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="App Name"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <FormControl fullWidth margin="normal">
            <FormLabel>Redirect URIs</FormLabel>
            <TextareaAutosize
              {...register('redirect_uris', {
                required: true,
                validate: validateRequiredField,
              })}
              required
              minRows={3}
            />
            <FormHelperText>
              Enter each redirect URI on a separate line
            </FormHelperText>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Typography
              component="a"
              href="https://symania.com/docs.php"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: '#0B56EB',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              https://symania.com/docs.php
              <img
                width="20"
                height="20"
                src={Images.icLink}
                alt="link-icon"
                style={{ marginLeft: '6px' }}
              />
            </Typography>
          </Box>
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box
              sx={{
                padding: '12px 60px',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  '&:hover': { backgroundColor: '#111111' },
                  backgroundColor: '#111111',
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {'Create'}
              </Button>
            </Box>
          </Box>
        </form>
        <Snackbar
          open={snack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={() => setSnack(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              background: '#303030 !important',
              borderRadius: '30px',
            },
          }}
          message="Copied to Clipboard"
        />
      </Box>
    </Modal>
  )
}

type UpdateOAuthClientInput = {
  client_id: string
  name: string
  redirect_uris: string
}

type UpdateOAuthClientModalProps = {
  isOpen: boolean
  handleUpdate: (oauthClient: any) => void
  handleCancel: () => void
  isSubmitting: boolean
  spToUpdate: any
}

const UpdateSpModal: FC<UpdateOAuthClientModalProps> = ({
  isOpen,
  handleUpdate,
  handleCancel,
  isSubmitting,
  spToUpdate,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    reset,
  } = useForm<UpdateOAuthClientInput>()

  const { orgId } = useParams()
  const [snack, setSnack] = useState(false)

  const copy = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url)
      setSnack(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    reset(spToUpdate)
  }, [isOpen])

  const createSp = () => {
    if (!isDirty || !isValid) return
    const oauthClient = getValues()
    oauthClient.redirect_uris = oauthClient.redirect_uris.replace('\n', ' ')
    handleUpdate(oauthClient)
  }

  if (!spToUpdate) return null

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: 'white',
          position: 'absolute',
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            Update OAuth Client {spToUpdate.id}
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <Box className="title-modal">
          <Box sx={{ marginTop: 2 }}>
            <Box sx={{ marginBottom: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography fontSize={18}>OAuth2 Connection Details</Typography>
                <Button
                  sx={{ color: '#111111' }}
                  href={`${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                  download="metadata.xml"
                >
                  <TfiDownload size={16} />
                  <Typography sx={{ marginLeft: '5px' }} fontSize={16}>
                    Metadata
                  </Typography>
                </Button>
              </Box>

              <Typography color="#666666" variant="body1" component="p">
                Add the following information to your SAML Service Provider
              </Typography>
            </Box>
            <Typography color="#222449" variant="body1" component="p">
              <span style={{ color: '#666666' }}>Metadata URL:</span>
              <br></br>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`}
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    copy(
                      ` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/metadata`
                    )
                  }
                  width={24}
                  height={24}
                  src={Images.icCopy}
                />
              </div>
            </Typography>
            <Typography color="#222449" mt={2} variant="body1" component="p">
              <span style={{ color: '#666666' }}>SSO URL:</span>
              <br></br>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`}
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    copy(` ${env.API_URL}/${ENDPOINTS.SAML}/org/${orgId}/sso`)
                  }
                  width={24}
                  height={24}
                  src={Images.icCopy}
                />
              </div>
            </Typography>
          </Box>
        </Box>
        <form
          style={{ padding: '30px 60px', width: '100%' }}
          onSubmit={handleSubmit(createSp)}
        >
          <TextField
            {...register('name', {
              required: true,
              validate: validateRequiredField,
            })}
            required
            label="App Name"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <FormControl fullWidth margin="normal">
            <FormLabel>Redirect URIs</FormLabel>
            <TextareaAutosize
              {...register('redirect_uris', {
                required: true,
                validate: validateRequiredField,
              })}
              required
              minRows={3}
            />
            <FormHelperText>
              Enter each redirect URI on a separate line
            </FormHelperText>
          </FormControl>
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box
              sx={{
                padding: '12px 60px',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  '&:hover': { backgroundColor: '#111111' },
                  backgroundColor: '#111111',
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {'Update'}
              </Button>
            </Box>
          </Box>
        </form>
        <Snackbar
          open={snack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={() => setSnack(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              background: '#303030 !important',
              borderRadius: '30px',
            },
          }}
          message="Copied to Clipboard"
        />
      </Box>
    </Modal>
  )
}

export default OAuthClientPage
