import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  createOrganizationByUserFn,
  CreateOrganizationByUserInput,
  newEmailVerificationFn,
} from 'src/api'
import { Images } from 'src/assets'

interface CreateOrgByUserFormProps {
  userName: string
  email: string
  isOpen: boolean
  newEmail: string
  onClose: () => void
}

function CreateOrgByUser({
  userName,
  email,
  isOpen,
  newEmail,
  onClose,
}: CreateOrgByUserFormProps) {
  const [selectedEmail, setSelectedEmail] = useState<'default' | 'new'>(
    'default'
  )
  const [showSuccessWindow, setShowSuccessWindow] = useState<boolean>(false)
  const [showSuccessEmailVerifyWindow, setShowSuccessEmailVerifyWindow] =
    useState<boolean>(false)
  const navigate = useNavigate()

  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit: formHandleSubmit,
    getValues,
    reset,
    control,
    watch,
  } = useForm<CreateOrganizationByUserInput>({
    defaultValues: {
      userName,
      email: newEmail || email,
      orgName: '',
      orgDomain: '',
      numOfUsers: '',
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEmail(event.target.value as 'default' | 'new')
  }

  const clearState = () => {
    navigate(window.location.pathname, { replace: true, state: null })
  }

  const handleCancel = () => {
    reset()
    setSelectedEmail('default')
    onClose()
    setShowSuccessWindow(false)
    setShowSuccessEmailVerifyWindow(false)
    clearState()
  }
  const { mutate: emailVerify } = useMutation(newEmailVerificationFn, {
    onSuccess: () => {
      reset()
      setShowSuccessEmailVerifyWindow(true)
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })

  const { mutate: createOrganization, isLoading: isCreatingOrganization } =
    useMutation(createOrganizationByUserFn, {
      onSuccess: () => {
        reset()
        setShowSuccessWindow(true)
      },
      onError: (error: any) => {
        alert(error.response.data.message)
      },
    })

  const handleCreateOrganization = async (
    organization: CreateOrganizationByUserInput
  ) => {
    createOrganization({
      userName: organization.userName,
      email: organization.email,
      orgName: organization.orgName,
      orgDomain: organization.orgDomain,
      numOfUsers: organization.numOfUsers,
    })
  }

  const sendSubmit = async () => {
    if (!isValid) {
      return
    }
    if (selectedEmail === 'new') {
      const newEmail = getValues().newEmail
      return newEmail && emailVerify(newEmail)
    }

    const organizationData = getValues()
    return await handleCreateOrganization(organizationData)
  }

  const newEmailValue = watch('newEmail')
  const emailValue = watch('email')

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: 'white',
          position: 'absolute',
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal font-inter">
          <Typography variant="h6" component="h2" className="font-inter">
            Create new organization
          </Typography>
          <Button
            sx={{ cursor: 'pointer', padding: 0, minWidth: '24px' }}
            onClick={handleCancel}
          >
            <img width="24" height="24" src={Images.icWhiteCloseBtn} />
          </Button>
        </Box>
        {showSuccessWindow ? (
          <Box sx={{ padding: '40px' }}>
            <Box
              sx={{
                marginTop: '202px',
                textAlign: 'center',
              }}
            >
              <img
                width="48"
                height="48"
                src={Images.icOkay}
                style={{ margin: '0 auto 24px auto' }}
              />
              <Typography
                variant="h6"
                component="h2"
                className="mb-[10px] font-inter font-semibold text-[24px]"
              >
                Your organization creation request has
                <br /> been successfully submitted!
              </Typography>
              <Typography className="mb-[28px] font-inter text-[18px]">
                It will be processed and approved within 24 hours.
                <br /> We will send you an update via email once the
                <br />
                organization is set up.
              </Typography>
              <Typography className="mb-[32px] font-inter text-[18px]">
                Thank you for your patience!
              </Typography>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{
                  '&:hover': { backgroundColor: '#111111' },
                  color: 'white',
                  background: '#303030',
                  padding: '10px 26px',
                  borderRadius: '12px',
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        ) : showSuccessEmailVerifyWindow ? (
          <Box sx={{ padding: '40px' }}>
            <Box
              sx={{
                marginTop: '202px',
                textAlign: 'center',
              }}
            >
              <img
                width="48"
                height="48"
                src={Images.icShieldOk}
                alt="icShieldOk"
                style={{ margin: '0 auto 24px auto' }}
              />
              <Typography
                variant="h6"
                component="h2"
                className="mb-[10px] font-inter font-semibold text-[24px]"
              >
                Email verification
              </Typography>
              <Typography className="mb-[28px] font-inter text-[18px]">
                A verification link has been sent to your email,
                <br /> verify this link, in order to proceed.
              </Typography>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{
                  '&:hover': { backgroundColor: '#111111' },
                  color: 'white',
                  background: '#303030',
                  padding: '10px 26px',
                  borderRadius: '12px',
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        ) : (
          <form
            style={{ padding: '40px', width: '100%', color: '#111111' }}
            onSubmit={formHandleSubmit(sendSubmit)}
          >
            <Typography
              sx={{ fontSize: '18px', mb: '40px' }}
              className="font-inter"
            >
              {newEmail ? (
                <Typography className="font-bold">
                  Finish creating your organization by completing all required
                  fields.
                </Typography>
              ) : (
                <Typography className="font-bold">
                  This email will be used to enter the organization.
                  <br /> You can either choose the email you're registered with
                  in the system or select a new one.
                </Typography>
              )}
            </Typography>
            <FormControl component="fieldset">
              {newEmail ? (
                <div className="space-y-11">
                  <div>
                    <Typography className="font-semibold font-inter">
                      Email
                    </Typography>
                    <Typography className="text-[#797880] font-inter">
                      {newEmail}
                    </Typography>
                  </div>
                  <TextField
                    label="Organization Name"
                    variant="outlined"
                    fullWidth
                    {...register('orgName', {
                      required: 'Organization Name is required',
                    })}
                    error={!!errors.orgName}
                    helperText={errors.orgName?.message}
                  />
                  <TextField
                    label="Organization Domain"
                    variant="outlined"
                    fullWidth
                    disabled={selectedEmail !== 'default'}
                    {...register('orgDomain', {
                      required: 'Organization Domain is required',
                      pattern: {
                        value: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                        message: 'Invalid domain format',
                      },
                    })}
                    error={!!errors.orgDomain}
                    helperText={errors.orgDomain?.message}
                  />
                  <FormControl
                    fullWidth
                    variant="outlined"
                    disabled={selectedEmail !== 'default'}
                  >
                    <InputLabel>Number Of Users</InputLabel>
                    <Select
                      {...register('numOfUsers', {
                        required: 'Number of users is required',
                      })}
                      label="Number Of Users"
                      error={!!errors.numOfUsers}
                      defaultValue=""
                    >
                      <MenuItem value="1-5">1 - 5</MenuItem>
                      <MenuItem value="6-10">6 - 10</MenuItem>
                      <MenuItem value="11-20">11 - 20</MenuItem>
                      <MenuItem value="21-50">21 - 50</MenuItem>
                      <MenuItem value="50-100">51-100</MenuItem>
                      <MenuItem value="100+">100+</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <RadioGroup
                  value={selectedEmail}
                  onChange={handleChange}
                  className="space-y-11"
                >
                  <Box>
                    <FormControlLabel
                      value="default"
                      control={<Radio className="text-black" />}
                      label={
                        <span className="font-semibold font-inter">
                          Default Email
                        </span>
                      }
                    />
                    <Typography className="ml-7 text-[#797880] font-inter">
                      {email}
                    </Typography>
                  </Box>

                  <div className="space-y-11">
                    <TextField
                      label="Organization Name"
                      variant="outlined"
                      fullWidth
                      disabled={selectedEmail !== 'default'}
                      {...(selectedEmail === 'default'
                        ? register('orgName', {
                            required: 'Organization Name is required',
                          })
                        : {})}
                      error={!!errors.orgName}
                      helperText={errors.orgName?.message}
                    />
                    <TextField
                      label="Organization Domain"
                      variant="outlined"
                      fullWidth
                      disabled={selectedEmail !== 'default'}
                      {...(selectedEmail === 'default'
                        ? register('orgDomain', {
                            required: 'Organization Domain is required',
                            pattern: {
                              value: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                              message: 'Invalid domain format',
                            },
                          })
                        : {})}
                      error={!!errors.orgDomain}
                      helperText={errors.orgDomain?.message}
                    />
                    <FormControl
                      fullWidth
                      variant="outlined"
                      disabled={selectedEmail !== 'default'}
                    >
                      <InputLabel>number of users</InputLabel>
                      <Select
                        {...(selectedEmail === 'default'
                          ? register('numOfUsers', {
                              required: 'Number of users is required',
                            })
                          : {})}
                        label="number of users"
                        error={!!errors.numOfUsers}
                        defaultValue=""
                      >
                        <MenuItem value="1-5">1 - 5</MenuItem>
                        <MenuItem value="6-10">6 - 10</MenuItem>
                        <MenuItem value="11-20">11 - 20</MenuItem>
                        <MenuItem value="21-50">21 - 50</MenuItem>
                        <MenuItem value="50-100">51-100</MenuItem>
                        <MenuItem value="100+">100+</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div>
                    <FormControlLabel
                      value="new"
                      control={
                        <Radio
                          sx={{
                            color: 'black',
                            '&.Mui-checked': {
                              color: 'black',
                            },
                          }}
                        />
                      }
                      label={
                        <span className="font-semibold font-inter">
                          New Email
                        </span>
                      }
                    />
                    <Typography className="ml-7 mb-6 text-[#797880] font-inter">
                      If you choose a new email, you will receive an email
                      verification to continue the process
                    </Typography>
                    <TextField
                      label="New Email"
                      variant="outlined"
                      fullWidth
                      disabled={selectedEmail !== 'new'}
                      {...(selectedEmail === 'new'
                        ? register('newEmail', {
                            required: 'New Email is required',
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: 'Invalid email format',
                            },
                          })
                        : {})}
                      error={!!errors.newEmail}
                      helperText={errors.newEmail?.message}
                    />
                  </div>
                </RadioGroup>
              )}
            </FormControl>
            <Box
              sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}
            >
              <Divider sx={{ background: '#DCDBE8' }} />
              <Box
                sx={{
                  padding: '12px 60px',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Button
                  variant="text"
                  onClick={handleCancel}
                  sx={{
                    color: '#303030',
                    padding: '12px 26px',
                    borderRadius: '12px',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    '&:hover': { backgroundColor: '#111111' },
                    backgroundColor: '#303030',
                    width: '97px',
                    padding: '12px 26px',
                    borderRadius: '12px',
                    '&.Mui-disabled': {
                      backgroundColor: '#303030',
                      color: 'white',
                      cursor: 'not-allowed',
                    },
                  }}
                  disabled={
                    !isDirty ||
                    (selectedEmail === 'new' && newEmailValue === emailValue) ||
                    isCreatingOrganization
                  }
                >
                  {selectedEmail === 'default' ? 'Create' : 'Apply'}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  )
}

export default CreateOrgByUser
